@import '~@src/styles/bootstrap/_utils';

.overlay {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;

  overflow-y: scroll;

  background-color: rgba(0,0,0,0.5);

  @include media-breakpoint-up(md) {
    align-items: center;
  }
}

.container {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 90%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.closeButton {
  border: none;
  padding: 0;
  background-color: transparent;
  color: $aliadaGrayLight;

  position: absolute;
  top: 52px;
  right: 32px;

  cursor: pointer;
}
