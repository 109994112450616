@import '~@src/styles/bootstrap/_utils';

.container {
  position: relative;
  background: $white;
  border-radius: $border-radius;
  box-shadow: 0 0.143rem 0.357rem 0 rgba($black, 0.08);
  overflow: hidden;
}

.clickable {
  cursor: pointer;
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    box-shadow: 0 0.143rem 1.429rem 0 rgba($black, 0.16);
    transition: box-shadow 0.3s ease-in-out;
  }
}

.loading {
  background: #dddfe3;
}
