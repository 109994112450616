@import '~@src/styles/bootstrap/_utils';

.title {
  margin-bottom: 1rem;
}

.summaryContainer {
  max-width: 99.286rem;
}

.summaryRow {
  justify-content: space-between;
  margin-bottom: 2rem;
}

.summarySubtitle {
  margin-bottom: 2rem;
}

.summaryCard {
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: start;

  h3 {
    padding-top: 1.714rem;
    padding-bottom: 1.714rem;
    padding-left: 1.142rem;
    border-bottom: 1px solid $tandem-light-gray;
  }

  &Body {
    margin: 1rem 0;
    padding-right: 1.142rem;
    padding-left: 1.142rem;
    line-height: 1.285rem;
    color: $tandem-light-gray;
  }
}

.emptyNextEventsCardBody {
  position: relative;
  width: 60%;
  padding: 1.714rem;
}

.emptyNextEventsCardBackground {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100%;
  z-index: 0;
}

.summaryBalance {
  line-height: 2.285rem;
}

.currencyCode {
  font-size: 1rem;
  line-height: 1.857rem;
  margin-right: 0.357rem;
}

.summaryAmountNegative {
  font-weight: 600;
  color: $red;
}

.summaryAddBalance {
  padding-top: 1.14rem;
  padding-bottom: 1.14rem;
  text-align: center;
  border-top: 1px solid $tandem-light-gray;
}

// Next Events stuff
.nextEvent {
  margin-bottom: 1.142rem;
  padding: 1.142rem;
  border-radius: 4px;
  background-color: $tandem-background-gray;
}

.nextEventInfo {
  width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  .icon {
    margin-right: 0.357rem;
  }
}

// Tasks stuff

.tasksScreen {
  height: 100%;
  padding: 1.142rem 1.714rem;
  overflow-y: scroll;
}

.newTaskDot {
  display: inline-block;
  width: 1.714rem;
  height: 1.714rem;
  border: 1px dotted $tandem-light-gray;
  border-radius: 50%;
}

.newTaskForm {
  padding-bottom: 1rem;
  border-bottom: 1px solid $tandem-light-gray;
}

.newTaskFormRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.newTaskFormGroup {
  display: flex;
  align-items: center;
  width: 75%;

  > div {
    width: 100%;
    margin: 0;
  }
}

.newTaskInput {
  border: none;
}

.newTaskButton {
  background-color: $tandem-blue;
  padding-top: 0.714rem;
  padding-bottom: 0.714rem;
}

.taskContainer {
  display: flex;
  margin: 1rem 0;
  padding: 0 0 1.357rem 0;
  border-bottom: 1px solid $tandem-light-gray;
  justify-content: space-between;
}

.taskStatus {
  display: flex;
  align-items: center;
}

.taskName {
  padding: 0 1.142rem;
  color: $tandem-dark-gray;
  font-size: 0.857rem;
}

.taskNameFinished {
  padding: 0 1.142rem;
  font-size: 0.857rem;
  color: $tandem-gray;
  text-decoration: line-through;
}

.taskFinished {
  font-size: 1.714rem;
  color: $tandem-accent-success;
}

.taskNotFinished {
  display: block;
  width: 1.714rem;
  height: 1.714rem;
  border: 1px solid $tandem-light-gray;
  border-radius: 50%;
}

.taskFinishedDate {
  font-size: 0.857rem;
  color: $tandem-gray;
}

.taskDeleteButton {
  appearance: none;
  border: none;
  color: $tandem-link-blue;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    color: red;
  }
}

.noServiceIcon {
  font-size: 3.4rem;
  color: $tandem-light-gray;
}

.noServiceText {
  margin: 1rem;
}
