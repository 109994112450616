@mixin loading-animation($color1: $aliadaGrayLight, $color2: lighten($aliadaGrayLight, 10%)) {
  @keyframes loading {
    0% {
      background-color: $color1;
    }
    50% {
      background-color: $color2;
    }
    100% {
      background-color: $color1;
    }
  }

  animation: loading ease-in-out 5s infinite;
}
