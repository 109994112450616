@import '~@src/styles/bootstrap/_utils';

.sidebar {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: calc(100vh - 5.143rem);
  padding-left: 1.714rem;
  padding-right: 0;
  box-shadow: 0 2px 5px 0 rgba(13,12,12,0.08);
}

.dashboardNav {
  margin-top: 2.286rem;
  flex: 1;
}

.separator {
  width: 2.857rem;
  border-top: 1px solid $tandem-light-gray;
  margin-bottom: 0.857rem;
}

.sidebarFooter {
  margin-bottom: 2.571rem;
}

.dashboard {
  padding: 2.286rem;
}
