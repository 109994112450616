@import '~@src/styles/bootstrap/_utils';

:global {
  .SingleDatePicker {
    display: block;
    position: relative;
    z-index: 10;
  }

  .SingleDatePickerInput {
    display: flex;
    align-items: center;
    border: 1px solid $tandem-light-gray;
    border-radius: $border-radius;

    &:after {
      flex: 0 0 auto;
      display: block;
      font-family: 'aliada-icons';
      content: '\e910';
      color: $tandem-light-gray;
      margin-right: 16px;
      font-size: 16px;
    }
  }

  .DateInput {
    flex: 1 1 auto;
    &.DateInput__withCaret {
      &:after, &:before {
        display: none;
      }
    }
  }

  .DateInput_input__focused {
    border: 0;
  }

  .DateInput_input {
    font-size: 12px;
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
    color: $black;
    font-weight: 400;
  }

  .SingleDatePicker_picker {
    top: 56px;
  }

  .DayPicker {
    border: 1px solid $tandem-light-gray;
    border-radius: $border-radius;
    box-shadow: 0 2px 5px 0 rgba(13,12,12,0.08);
  }

  .DayPicker_weekHeader {
    small {
      font-size: 11px;
      text-transform: uppercase;
      color: $black;
      font-weight: 600;
    }
  }

  .CalendarDay_container {
    border: 0;
    border-radius: 50%;
    transition: background 0.25s ease-in-out;

    &:hover {
      border: 0;
      transition: background 0.25s ease-in-out;
    }
  }

  .CalendarDay_button {
    color: $tandem-dark-gray;
    font-size: 11px;
  }

  .CalendarDay__selected {
    background-color: $tandem-link-blue;
    transition: background-color 0.2s ease-in-out;

    .CalendarDay_button {
      color: $white;
    }

    &:hover {
      background-color: darken($tandem-link-blue, 20%);
      transition: background-color 0.25s ease-in-out;
    }
  }

  .CalendarDay__blocked_out_of_range,
  .CalendarDay__blocked_out_of_range:hover {
    border: 0;

    .CalendarDay_button {
      color: $tandem-light-gray;
      cursor: not-allowed;
    }
  }

  .DayPickerNavigation_button {
    border: 0;
    padding: 0;
    font-size: 25px;
  }

  .CalendarMonth_caption {
    padding-top: 15px;
    padding-bottom: 50px;

    strong {
      font-size: 11px;
      color: $tandem-gray;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}
