@import '~@src/styles/bootstrap/_utils';

.container {
  padding: 2.286rem; /* 32/14 */
  border-radius: $border-radius;
  background-color: #FFFFFF;
	box-shadow: 0 0.143rem 0.357rem 0 rgba(0,0,0,0.08); /* 2/14, 5/14 */

  @include media-breakpoint-up(md) {
    width: 38.571rem; /* 540/14 */
  }
}

.body {
  margin-top: 2.143rem; /* 30/14 */
}

.options {
  border: 0.071rem solid $aliadaGrayLight; /* 1/14 */
  margin-bottom: 1.714rem; /* 24/14 */
  border-radius: $border-radius;
  overflow: hidden;
}

.option {
  + .option {
    border-top: 0.071rem solid $aliadaGrayLight; /* 1/14 */
  }
}

.optionHeader {
  padding: 1.714rem; /* 24/14 */
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: $aliadaGrayBackground;

  > div {
    flex: 1 1 auto;
  }

  + .optionBody {
    border-top: 0.071rem solid $aliadaGrayLight; /* 1/14 */
  }
}

.selectedIcon {
  flex: 0 0 auto;
  display: block;
  width: 1.429rem; /* 20/14 */
  height: 1.429rem; /* 20/14 */
  line-height: 1.429rem;
  color: $white;
  background-color: $white;
  font-size: 0.714rem; /* 10/14 */
  font-weight: bold;
  border-radius: 50%;
  text-align: center;
  margin-right: 1.5rem; /* 21/14 */
  border: 1px solid $aliadaGrayLight;
}

.existingCard {
  display: flex;

  > img {
    flex: 0 0 auto;
    margin-right: 1.143rem; /* 16/14 */
    height: 18px;
    width: 26px;
  }

  > div {
    flex: 1 1 auto;
  }

  :global(.text-small-body-2) {
    margin-bottom: 0.571rem; /* 8/14 */
  }
}

.optionBody {
  padding: 1.714rem 2.857rem; /* 24/14, 40/14 */
}

.optionSelected {
  .selectedIcon {
    border-color: $green;
    background-color: $green;
  }

  .optionHeader {
    background-color: $white;
  }
}

.paymentMethodTitle {
  margin-bottom: 0.571rem; /* 8/14 */
}

.paymentMethodDescription {
  margin: 0;
}

.buttons {
  text-align: right;
}

.creditCardIcon {
  margin-right: 1.143rem; /* 16/14 */
}
