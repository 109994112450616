@import '~@src/styles/bootstrap/_utils';

.productCard {
  position: relative;
  width: 15rem;
  height: 14.357rem;
  padding: 1.143rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectedCard {
  box-shadow: 0 0.143rem 0.357rem 0 rgba(13,12,12,0.08);
  border: 0.071rem solid $tandem-accent-success;
}

.image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
}

.imageWrapper {
  height: 4.714rem;
  width: 10.857rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.857rem;
}

.title {
  height: 5.0rem;
  padding: 0 0.5rem;
  line-height: 1.429rem;
  overflow-y: auto;
}

.unit {
  margin-bottom: 0;
}

.selectedIcon {
  display: block;
  position: absolute;
  top: 1.143rem;
  right: 1.143rem;
  width: 1.429rem;
  height: 1.429rem;
  color: white;
  background-color: $tandem-accent-success;
  font-size: 0.714rem;
  font-weight: bold;
  line-height: 1.6rem;
  border-radius: 50%;
}
