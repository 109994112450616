@import '~@src/styles/bootstrap/_utils';

.container {
  display: flex;
  flex-direction: column;

  background-color: $white;
  border-radius: 0.286rem; /* 4/14 */
	box-shadow: 0 0.143rem 0.357rem 0 rgba(0,0,0,0.08); /* 2/14, 5/14 */
  padding: 24px 40px;
  width: 100%;
  max-width: 540px;
}

.title {
  align-self: center;
  margin-bottom: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.submitButton {
  align-self: flex-end;
}
