@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

$font-family-OpenSans: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-Maax: 'Maax', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$font-family-Geomanist: 'Geomanist', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

@font-face{
  font-family: Maax;
  src: url(/fonts/maax/Sans-Bold.eot?#iefix) format('embedded-opentype'),url(/fonts/maax/Sans-Bold.woff) format('woff'),url(/fonts/maax/Sans-Bold.woff2) format('woff2'),url(/fonts/maax/Sans-Bold.ttf) format('truetype'),url(/fonts/maax/Sans-Bold.svg) format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face{
  font-family: Geomanist;
  src: url(/fonts/geomanist/Geomanist-Regular.eot?#iefix) format('embedded-opentype'),url(/fonts/geomanist/Geomanist-Regular.woff) format('woff'),url(/fonts/geomanist/Geomanist-Regular.woff2) format('woff2'),url(/fonts/geomanist/Geomanist-Regular.ttf) format('truetype'),url(/fonts/geomanist/Geomanist-Regular.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face{
  font-family: Geomanist;
  src: url(/fonts/geomanist/Geomanist-Bold.eot?#iefix) format('embedded-opentype'),url(/fonts/geomanist/Geomanist-Bold.woff) format('woff'),url(/fonts/geomanist/Geomanist-Bold.woff2) format('woff2'),url(/fonts/geomanist/Geomanist-Bold.ttf) format('truetype'),url(/fonts/geomanist/Geomanist-Bold.svg) format('svg');
  font-weight: bold;
  font-style: normal;
}
