@import '~@src/styles/bootstrap/_utils';

.loaderContainer {
  position: relative;
  width: 42px;
  height: 42px;
}

.loader {
  animation: rotate 1s linear infinite;
}

.path {
  stroke: $tandem-link-blue;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-width: 5;
  stroke-miterlimit: 10;

  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
