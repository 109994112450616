@import '~@src/styles/bootstrap/_utils';

.wrapper {
  background: $aliadaGray;
  padding: 1.143rem 0;
}

.container {
  display: flex;
  align-items: center;
}

.icon {
  flex: 0 0 auto;
  font-size: 1.429rem; /* 20/14 */
  margin-right: 1.143rem; /* 16/14 */
}

.content {
  flex: 1 1 auto;
}

.closeButton {
  flex: 0 0 auto;
  cursor: pointer;
}

.success {
  background-color: $aliadaAccentSuccessBackground;

  .icon {
    color: $aliadaAccentSuccess;
  }
}

.info {
  background-color: $aliadaAccentInfoBackground;

  .icon {
    color: $aliadaAccentInfo;
  }
}

.warning {
  background-color: $aliadaAccentWarningBackground;

  .icon {
    color: $aliadaAccentWarning;
  }
}

.danger {
  background-color: $aliadaAccentErrorBackground;

  .icon {
    color: $aliadaAccentError;
  }
}
