@import '~@src/styles/bootstrap/_utils';

.container {
  padding: 2.286rem; /* 32/14 */
  border-radius: $border-radius;
  background-color: #FFFFFF;
	box-shadow: 0 0.143rem 0.357rem 0 rgba(0,0,0,0.08); /* 2/14, 5/14 */

  @include media-breakpoint-up(md) {
    width: 38.571rem; /* 540/14 */
  }
}

.buttons {
  text-align: right;
  margin-top: 1rem;
}
