@import '~@src/styles/bootstrap/_utils';

.container{
  background-color: $aliadaGray;
  color: $white;
  font-size: 2rem;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.default {
  width: 3.429rem;
  height: 3.429rem;
}

.small {
  width: 2.429rem;
  height: 2.429rem;
}

.large {
  width: 7.143rem;
  height: 7.143rem;
}
