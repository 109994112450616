@import '~@src/styles/bootstrap/_utils';

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;

  width: 100%;
  padding: 2.286rem;

  border-radius: 0.286rem;
  background-color: #FFFFFF;
  box-shadow: 0 0.143rem 0.357rem 0 rgba(0,0,0,0.08);

  @include media-breakpoint-up(md) {
    width: 38.571rem;
  }
}
