@import '~@src/styles/bootstrap/_utils';

.container {
  border-radius: $border-radius;
  background-color: #FFFFFF;
	box-shadow: 0 0.143rem 0.357rem 0 rgba(0,0,0,0.08); /* 2/14, 5/14 */

  padding: 2.286rem 2.286rem 1.714rem 2.286rem; /* 32/14, 32/14, 24/14, 32/14 */

  @include media-breakpoint-up(md) {
    width: 38.571rem; /* 540/14 */
  }
}

.title {
  margin-bottom: 1.143rem; /* 16/14 */
}

.description {
  text-align: center;
  margin-bottom: 1.714rem; /* 24/14 */
}

.error {
  margin-top: 1.143rem; /* 16/14 */
  margin-bottom: 1.143rem; /* 16/14 */
}

.buttons {
  text-align: right;

  button:not(:last-of-type)  {
    margin-right: 1.143rem; /* 16/14 */
  }
}

.cancelButton {
  text-transform: capitalize;
  color: $tandem-dark-gray;
  font-weight: 600;
  font-size: 0.857rem;

  &:hover {
    text-decoration: none;
  }
}
