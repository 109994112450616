// Fonts
html { // Sets the default size, for the rem units to make sense
  font-size: 14px;
}

h2, h4, h6 {
  font-weight: bold;
}

h5, h6 {
  font-family: $font-family-OpenSans;
}

h1, h3, h5 {
  font-weight: normal;
}

// Custom font styles
.text-title-2 {
  font-family: $font-family-Maax;
  font-size: 0.929rem;
  font-weight: bold;
}

.text-small-body {
  font-family: $font-family-OpenSans;
  font-size: 0.857rem;
}

.text-small-body-2 {
  @extend .text-small-body;
  font-weight: 600;
}

.text-title-3 {
  font-family: $font-family-OpenSans;
  font-size: 0.786rem;
  font-weight: normal;
}

.text-small {
  font-family: $font-family-OpenSans;
  font-size: 0.786rem;
  font-weight: normal;
}

.text-small-2 {
  @extend .text-small;
  font-weight: normal;
}

.text-body {
  font-size: 1rem;
  font-family: $font-family-OpenSans;
  line-height: 1.5rem;
}

.text-body-2 {
  @extend .text-body;
  font-weight: 400;
}

.text-headline {
  @extend h6;
  font-weight: normal;
  line-height: 1.5rem;
}

.text-headline-2 {
  @extend h5;
}

.text-title {
  @extend h4;
  font-family: $font-family-Geomanist;
  font-weight: normal;
}

.text-title-1 {
  @extend h3;
}

.text-jumbo {
  @extend h2;
}

.text-h1 {
  @extend h1;
}

.text-black {
  color: $black;
}

.link-dark {
  color: $tandem-gray;

  &:hover {
    color: $tandem-dark-gray;
  }
}

// Buttons
.btn {
  text-transform: uppercase;
  font-size: 0.786rem;
  letter-spacing: 0.05rem;
}

.btn-default, .btn-primary {
  color: $white;

  &:hover {
    color: $white;
  }
}

.btn-default {
  background: $black;

  &:hover:enabled {
    box-shadow: 0 0.143rem 0.357rem 0 rgba($black, 0.5);
  }
}

.btn-primary {
  &:hover:enabled {
    background: white;
    color: $tandem-blue;
    border: 1px solid $tandem-blue;
  }
}

.btn-outline-secondary {
  color: $tandem-dark-gray;

  &:hover:enabled {
    border-color: $black;
    color: $black;
    background: none;
  }
}

.btn-group-toggle {
  .btn {
    &.active {
      box-shadow: 0 2px 5px 0 rgba($black, 0.1);
    }
  }

  .btn-outline-secondary {
    background-color: $white;
    color: $tandem-gray;

    &.active {
      color: initial;
    }
  }
}

.btn-md {
  padding: 0.714rem 1.714rem;
}

.btn-cta {
  color: white;
  background: $tandem-link-blue;
  transition: box-shadow 0.25s ease-in-out;

  &:hover {
    color: white;
    box-shadow: 0 2px 5px 0 rgba(60,88,240,0.5);
    transition: box-shadow 0.3s ease-in-out;
  }
}

// Forms
.form-group {
  .form-control {
    font-size: 0.857rem;
    padding: 0.65rem 1.143rem;
  }

  label {
    font-size: 0.786rem;
    color: $tandem-dark-gray;
    font-weight: normal;
  }

  .form-text {
    font-size: 0.786rem;
    font-weight: 600;
    color: $tandem-dark-gray;

    &.invalid-feedback {
      color: $red;
    }
  }
}

.is-invalid ~ .invalid-feedback {
  display: block;
}

// Side nav
.nav-link {
  color: transparentize($tandem-black, 0.6);
  font-family: $font-family-OpenSans;
  font-size: 0.929rem;
  font-weight: 600;
  height: 3.714rem;
  line-height: 3.714rem;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 0.571rem;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $tandem-black;
    transition: color 0.25s ease-in-out;
  }

  .icon {
    display: block;
    width: 1.429em;
    height: 1.429em;
    float: left;
    color: $tandem-gray;
    font-size: 1.429rem;
    margin-right: 1.429rem;
  }

  &.active {
    color: $tandem-link-blue;
    border-right: 0.214rem solid $tandem-link-blue;

    .icon {
      color: $tandem-link-blue;
    }
  }
}

// Badges
.badge {
  line-height: 1.071rem; /* 15/14 */
}

// Utility classes
.clickable {
  cursor: pointer;
}

.noScroll {
  overflow: hidden;
}

.noHover {
  &:hover {
    text-decoration: none;
  }
}

// Colors

.link-blue {
  color: $tandem-link-blue;
}

.text-gray {
  color: $tandem-gray;
}

.negative {
  color: $tandem-accent-error;
}

.positive {
  color: $tandem-accent-success;
}

.tippy-popper {
  max-width: 300px;
}
