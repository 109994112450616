@import '~@src/styles/bootstrap/_utils';

.container {
  width: 238px;
  border-radius: 2px;
  border: 1px solid #DCDEE2;
  background-color: $white;
}

.currentUser {
  background-color: $black;
  padding: 16px;
  display: flex;
}

.avatar {
  margin-right: 16px;
}

.userAndActions {
  text-align: left;
  color: $white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    margin-bottom: 8px;
  }

  button {
    color: $white;
    padding: 0;
  }
}

.userEmail {
  font-size: 10px;
  max-width: 145px;
  word-wrap: break-word;
}

.logoutButton {
  padding: 0;
  border: 0;
  cursor: pointer;
}

.offices {
  max-height: 210px;
  overflow-y: scroll;
  margin-bottom: 0;

  li {
    padding: 16px;
    cursor: pointer;

    &:hover {
      background-color: rgba(#273794, 0.2);
    }
  }
}
