@import '~@src/styles/bootstrap/_utils';

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 0.286rem; /* 4/14 */
  background-color: #FFFFFF;
	box-shadow: 0 0.143rem 0.357rem 0 rgba(0,0,0,0.08); /* 2/14, 5/14 */

  @include media-breakpoint-up(md) {
    width: 38.571rem; /* 540/14 */
  }
}

.header {
  border-bottom: 1px solid $tandem-light-gray;
  padding: 30px 0;
}

.body {
  padding: 24px 40px;
}

.actionButtons {
  text-align: right;
}
