@import '~@src/styles/bootstrap/_utils';

.container {
  background-color: $white;
  padding: 0 4.571rem;
  height: 5.143rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  flex: 0 0 auto;
  margin: 0;
  font-size: 2rem;
  line-height: 2.429rem;

  &:hover {
    text-decoration: none;
    color: $black;
  }
}

.rightNav {
  flex: 0 0 auto;
  display: flex;
  align-self: stretch;
  align-items: center;
}

.loggedOut {
  flex: 0 0 auto;

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin-left: 2.857rem;
  }

  a {
    color: #000;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.loggedIn {
  margin-left: 1.143rem;
  align-self: stretch;
  display: flex;

  > div {
    border-left: 1px solid $tandem-light-gray;
  }
}

.userMenu {
  position: relative;
  display: flex;
  align-items: center;
}

.userButton {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdownMenu {
  position: absolute;
  top: 80px;
  right: 0;
  z-index: 1000;
}

.caret {
  display: block;
  margin-left: 1.143rem;
  width: 0;
  height: 0;
  border-left: 0.321rem solid transparent;
  border-right: 0.321rem solid transparent;
  border-top: 0.321rem solid $tandem-light-gray;
}

.negativeBalance {
  color: $tandem-accent-error;
}

.positiveBalance {
  color: $tandem-accent-success;
}
