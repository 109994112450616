@import '~@src/styles/bootstrap/_utils';

.navBarItem {
  display: flex;
  align-items: center;
  line-height: normal;

  &:global(.active) {
    .icon {
      color: $tandem-link-blue;
    }
  }
}

.icon {
  flex: 0 0 auto;
  font-size: 20px;
  margin-right: 25px;
}
