@import '~@src/styles/bootstrap/_utils';

.container {
  border-radius: $border-radius;
  background-color: #FFFFFF;
	box-shadow: 0 0.143rem 0.357rem 0 rgba(0,0,0,0.08); /* 2/14, 5/14 */

  @include media-breakpoint-up(md) {
    width: 38.571rem; /* 540/14 */
  }
}

.title {
  padding: 2.143rem 0; /* 30/14 */
}

.body {
  border-top: 0.071rem solid $aliadaGrayLight; /* 1/14 */
  padding: 1.143rem 2.857rem 1.714rem 2.857rem; /* 16/14, 40/14, 24/14, 40/14 */
}

.buttons {
  text-align: right;
  margin-top: 1rem;
}

.inviteAnotherUserButton {
  padding: 0;
}

.additionalEmailFieldContainer {
  width: 90%;
  display: inline-block;
}

.removeEmailButton {
  padding: 0;
  margin-left: 16px;
  font-size: 24px;
}
