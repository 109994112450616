@import '~@src/styles/bootstrap/_utils';

.container {
  position: relative;
}

.suggestionsList {
  max-height: 200px;
  width: 100%;
  margin-top: 8px;
  border: 1px solid #DCDEE2;
  border-radius: 2px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px 0 rgba(13,12,12,0.08);
  list-style: none;
  padding-left: 0;
  position: absolute;
  overflow-y: scroll;
  z-index: 1;
}

.hiddenSuggestionsList {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.suggestionItem {
  height: 50px;
  width: 100%;
  padding-left: 16px;

  display: flex;
  align-items: center;

  &:hover,
  &:focus {
  	background-color: transparentize($tandem-link-blue, 0.9);
  }
}

.activeSuggestionItem {
  background-color: transparentize($tandem-link-blue, 0.8);
}
