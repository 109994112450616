@import '~@src/styles/bootstrap/_utils';

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 2.857rem 4.571rem; /* 40/14, 64/14 */

  border-radius: 0.286rem; /* 4/14 */
  background-color: #FFFFFF;
	box-shadow: 0 0.143rem 0.357rem 0 rgba(0,0,0,0.08); /* 2/14, 5/14 */

  @include media-breakpoint-up(md) {
    width: 54.857rem; /* 768/14 */
  }

  @include media-breakpoint-up(lg) {
    width: 65.714rem; /* 920/14 */
  }
}

.column {
  width: 100%;
}

.body {
  @include media-breakpoint-up(lg) {
    max-width: 27.357rem; /* 383/14 */
  }
}

.form {
  @include media-breakpoint-up(md) {
    max-width: 25.214rem; /* 353/14 */
  }
}
