// Variables

$aliadaPink: #FFA791;
$aliadaPinkLight: #FFE5DE;
$aliadaPinkDark: #F88D73;

$aliadaGreen: #C6D2B0;
$aliadaGreenLight: #E3F5DF;
$aliadaGreenDark: #A3C38F;

$aliadaBlue: #A6E5F0;
$aliadaBlueLight: #D6F6FC;
$aliadaBlueDark: #84CCD8;

$aliadaYellow: #FEDDA2;
$aliadaYellowLight: #FFF5E2;
$aliadaYellowDark: #FFBD48;

$aliadaGray: #AFAFAF;
$aliadaGrayLight: #DCDEE2;
$aliadaGrayDark: #6C6C6C;
$aliadaGrayBackground: #F7F8F9;

$aliadaBluerple: #3C58F0;

$aliadaAccentSuccess: #54D46B;
$aliadaAccentSuccessBackground: #D9F6DE;
$aliadaAccentWarning: #FFBD48;
$aliadaAccentWarningBackground: $aliadaYellowLight;
$aliadaAccentError: #F75A5B;
$aliadaAccentErrorBackground: #FFE0E0;
$aliadaAccentInfo: $aliadaBlueDark;
$aliadaAccentInfoBackground: $aliadaBlueLight;

$tandem-black: #0D0C0C;
$tandem-gray: #AFAFAF;
$tandem-light-gray: #DCDEE2;
$tandem-dark-gray: #6C6C6C;
$tandem-background-gray: #F7F8F9;

$tandem-blue: #273794;
$tandem-orange: #FF7D3A;

$tandem-dark-blue: #2B2D37;
$tandem-link-blue: #3C58F0;
$tandem-light-blue: #9BA8E7;

$tandem-accent-success: #54D46B;
$tandem-accent-warning: #FFBD48;
$tandem-accent-error: #F75A5B;

$theme-colors: (
  primary: $tandem-dark-blue,
  secondary: $tandem-gray,
  success: $tandem-accent-success,
  info: $tandem-light-blue,
  warning: $tandem-accent-warning,
  danger: $tandem-accent-error,
  light: $tandem-light-gray,
  dark: $tandem-dark-gray
) !default;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #DCDEE2 !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #0D0C0C !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #F75A5B !default;
$orange:  #fd7e14 !default;
$yellow:  #FFBD48 !default;
$green:   #56BD5B !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;
