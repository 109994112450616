@import '~@src/styles/bootstrap/_utils';

.topButton {
  margin-bottom: 1.143rem; /* 16/14 */
}

.creditCardsList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.creditCardsListItem {
  margin-bottom: 1.143rem;  /* 16/14 */
}

.creditCard {
  width: 45.429rem; /* 636/14 */
  padding: 1.714rem 2.857rem; /* 24/14, 40/14 */
}

.cardTitle {
  margin-bottom: 1.714rem; /* 24/14 */
}

.methodWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trashIcon {
  margin-right: 8px;
}
