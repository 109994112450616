@import '~@src/styles/bootstrap/_utils';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.857rem 4.571rem; /* 40/14, 64/14 */

  border-radius: 0.286rem; /* 4/14 */
  background-color: #FFFFFF;
	box-shadow: 0 0.143rem 0.357rem 0 rgba(0,0,0,0.08); /* 2/14, 5/14 */

  @include media-breakpoint-up(md) {
    width: 54.857rem; /* 768/14 */
  }
}

.section {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.actionButtons {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(sm) {
    align-self: flex-end;
    flex-direction: row;
  }
}

.cancelButton {
  order: 2;

  text-transform: capitalize;

  @include media-breakpoint-up(sm) {
    order: 1;
  }
}

.confirmButton {
  order: 1;

  @include media-breakpoint-up(sm) {
    order: 2;
  }
}
