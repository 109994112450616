@import '~@src/styles/bootstrap/_utils';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  height: calc(100vh - 5.143rem);
}

.img {
  margin-bottom: 0.571rem; /* 8/14 */
}

.title {
  font-size: 6.429rem; /* 90/14 */
  line-height: 8.714rem; /* 122/14 */
  color: $aliadaGray;
  margin: 0;
}

.subtitle {
  margin-bottom: 1.143rem; /* 16/14 */
}

.description {
  margin-bottom: 2.857rem; /* 40/14 */
}
