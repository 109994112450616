@import 'variables';

.badge,
.badge-success,
.badge-info {
  color: $badge-color;
}

.btn-outline-secondary.active,
.btn-outline-secondary.active:not(:disabled):not(.disabled) {
  background-color: $white;
}
