@import '~@src/styles/bootstrap/_utils';

.copyableButton {
  height: 1.75rem;
  width: 1.75rem;
  padding: 0;
  border: none;
  border-radius: 50%;
  color: white;
  background-color: $tandem-link-blue;

  &:hover {
    cursor: pointer;
  }
}
