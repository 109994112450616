@import '~@src/styles/bootstrap/_utils';

ul.navBar {
  padding-bottom: 1rem; /* 14/14 */
  border-bottom: 0.071rem solid $aliadaGrayLight; /* 1/14 */

  li:not(:last-child) {
    margin-right: 2.857rem; /* 40/14 */
  }
}

.navLink {
  text-transform: uppercase;
  padding-bottom: 1rem; /* 14/14 */
  cursor: pointer;

  &--active {
    color: $black !important;
    border-bottom: 0.214rem solid black; /* 3/14 */
  }

  &:hover {
    text-decoration: none;
  }
}
