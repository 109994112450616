.container {
	width: 100%;
	max-width: 636px;
	border-radius: 4px;
	background-color: #FFFFFF;

  padding: 35px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  height: 53px;
  width: 71px;

  margin-bottom: 1.786rem; /* 25/14 */
}

.description {
  max-width: 28.571rem; /* 400/14 */
  text-align: center;
  line-height: 32px;
}
