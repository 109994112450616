@import '~@src/styles/bootstrap/_utils';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: $border-radius;
  background-color: #FFFFFF;
  box-shadow: 0 0.143rem 0.357rem 0 rgba(0,0,0,0.08); /* 2/14, 5/14 */

  padding: 2.286rem; /* 32/14 */

  @include media-breakpoint-up(md) {
    width: 38.571rem; /* 540/14 */
  }
}

.image {
  margin-bottom: 1.714rem; /* 24/14 */
}

.title {
  margin-bottom: 1.143rem; /* 16/14 */
}

.body {
  text-align: center;
  margin-bottom: 1.714rem; /* 24/14 */
}

.error {
  margin-top: 1.143rem; /* 16/14 */
  margin-bottom: 0;
}
