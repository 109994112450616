@import '~@src/styles/bootstrap/_utils';
@import '~@src/styles/mixins';

.loadingHeader {
  width: 100%;
  height: 4.5rem;
  background-color: white;
}

.loading {
  width: 100%;
  height: 15.5rem;
  margin-bottom: 2.571rem;
  @include loading-animation;
}
